import Vue from 'vue'
import App from './App.vue'
import router from './router'
import Bmob from 'hydrogen-js-sdk'

Vue.config.productionTip = false

new Vue({
  router,
  render: h => h(App)
}).$mount('#app')

// Bmob.initialize('3eb597d06089f564', 'hmmapp')
Bmob.initialize('8408f400be1ddc5f', 'qqmapp')
Vue.prototype.Bmob = Bmob
